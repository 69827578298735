<template>
  <div>
    <div class="banner">
      <el-carousel arrow="never">
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item">
          <img :src="item" :alt="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contBox">
      <div v-for="item in contBox" :key="item.id">
        <img :src="item.firstPicture" alt="" />
        <h3>
          <el-button @click="itemUrl(item)" type="text">{{
            item.name
          }}</el-button>
        </h3>
        <p>{{ item.introduction }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolutionDetails, findMoreScheme } from '../../api/parentPage'
export default {
  name: '更多解决方案',
  data() {
    return {
      bannerImg: [],
      id: '',
      contBox: []
    }
  },
  created() {
    this.id = this.$route.params.id
    this._initData()
  },
  methods: {
    _initData() {
      // 获取解决方案banner图片
      getSolutionDetails().then((res) => {
        if (res.status == 200) {
          this.bannerImg = res.data.advertisement.split(',')
        }
      })
      // 查询菜单对应信息
      findMoreScheme({ id: this.id }).then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          this.contBox = res.data
        }
      })
    },
    itemUrl(item) {
      // this.$router.push({ path: `/details/产品分类/${item.groupId}/${item.id}` });
      this.$router.push({ path: `/schemeDetails/${item.id}` })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  text-align: center;
  ::v-deep .el-carousel > .el-carousel__container {
    height: 650px !important;
    .el-carousel__item {
      width: 100%;
      text-align: center;
      > img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
  }
}
.contBox {
  width: 1200px;
  padding: 150px 0px 100px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    width: 350px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: start;
    margin-bottom: 50px;
    margin-right: 50px;
    > img {
      width: 320px;
      border-radius: 15px;
    }
    > h3 {
      margin: 10px 0px;
      > button {
        font-weight: 700;
        font-size: 18px;
        color: #484848;
        &:hover {
          color: #888888;
        }
      }
    }
    > p {
      font-weight: 500;
      font-size: 14px;
      color: #9a9a9a;
    }
  }
}
</style>
